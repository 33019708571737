<script setup lang="ts">
const { t: $t } = useI18n()

import type { CustomFolderTreeOption } from '~/types'

const props = defineProps<{ payload: CustomFolderTreeOption, entity: FileFolderEntity }>()
const emit = defineEmits<{ (e: 'close'): void }>()

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const deleteFolder = useMutation({
  mutationFn: $trpc.fileFolder.deleteSubFolder.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('fileFolder.deleteFolder.error') }),
  onSuccess: async () => {
    await Promise.all(getFileFolderQueryInvalidations(props.entity, queryClient))
    notification.success({ title: $t('fileFolder.deleteFolder.success'), duration: 4500 })
    emit('close')
  },
})

const deleteFile = useMutation({
  mutationFn: $trpc.file.deleteFile.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('file.delete.error') }),
  onSuccess: async () => {
    await Promise.all(getFileFolderQueryInvalidations(props.entity, queryClient))
    notification.success({ title: $t('file.delete.success'), duration: 4500 })
    emit('close')
  },
})

const isDeleting = computed(() => deleteFolder.isPending.value || deleteFile.isPending.value)

function deleteData() {
  if (props.payload.nodeType === 'file') {
    deleteFile.mutate({ path: props.payload.key })
  } else {
    deleteFolder.mutate({ id: props.payload.key })
  }
}
</script>

<template>
  <TheConfirmPopup
    :is-loading="isDeleting"
    show
    @confirm="deleteData"
    @close="$emit('close')"
  >
    <p>{{ $t(`fileFolder.${payload.nodeType}.delete.confirm`, { name: payload.label }) }}</p>
  </TheConfirmPopup>
</template>
